.shopCategories {
  text-align: center;
}

.tabsContainer {
  display: flex;
  flex-direction: column-reverse;
}

.shopAllContainer {
  display: flex;
  align-items: center;
  gap: 8px;
  margin-left: auto;
  justify-content: end;
}

.shopAllLink {
  align-self: flex-end;
}

.tabsContainer {
  flex-grow: 1;
}

.tabContent {
  margin-left: 1rem;
  margin-right: 1rem;
}

@media only screen and (max-width: 1000px) {
  .allCategoriesContainer {
    grid-template-columns: repeat(auto-fill, minmax(120px, 2fr));
  }

  .allCategoriesContainer {
    a {
      --sizeMod: 0.75;
    }

    a span {
      white-space: initial;
      text-align: center;
      display: flex;
      justify-content: center;
      align-items: center;
      min-height: 3.375rem;
      word-break: break-word;
    }

    img {
      max-width: 75%;
      height: auto;
      justify-self: flex-start;
    }
  }
}

@media (min-width: 1250px) {
  .tabsContainer {
    display: flex;
    flex-direction: column;
  }
}
