@media (min-width: 1023px) {
  .wrapper {
    display: flex;
    width: 100%;
  }

  .left {
    width: calc(50% - 0.5rem);
    margin-right: 1rem;
  }
  .right {
    display: flex;
    flex-direction: column;
    width: calc(50% - 0.5rem);
  }

  .card {
    margin-right: 1rem;
  }

  .fullHeight {
    height: 100%;
  }
}
