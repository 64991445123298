.container {
  position: relative;
}

@media (min-width: 501px) {
  .recommmendationLinkTop {
    position: absolute;
    right: 0;
    top: 3px;
  }
  .recommmendationLinkBottom {
    display: none;
  }
}

@media (max-width: 500px) {
  .recommmendationLinkTop {
    display: none;
  }
  .recommmendationLinkBottom {
    position: absolute;
    bottom: -2rem;
  }
}
