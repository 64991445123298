.makerContainer {
  overflow: hidden;
  aspect-ratio: 320/222;
  background-repeat: no-repeat;
  background-size: 400% 100%;
  -webkit-animation-duration: 1.5s;
  animation-duration: 1.5s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-name: placeholderShimmer;
  animation-name: placeholderShimmer;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
  position: relative;
}

.darkMaker {
  background: #000000;
  background-image: linear-gradient(
    to right,
    #161616 0%,
    #161616 20%,
    #161616 40%,
    #161616 100%
  );
}

.makerGradient {
  top: 0;
  position: absolute;
  height: 10%;
  width: 100%;
  background-image: linear-gradient(to bottom, black, transparent 50%);
}

.clickTrap {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
}

@media only screen and (min-width: 320px) {
  .makerContainer {
    aspect-ratio: 320/222;
  }
}

@media only screen and (min-width: 375px) {
  .makerContainer {
    aspect-ratio: 375/260;
  }
}

@media only screen and (min-width: 414px) {
  .makerContainer {
    aspect-ratio: 414/288;
  }
}

@media only screen and (min-width: 600px) {
  .makerContainer {
    aspect-ratio: 600/134;
  }
}

@media only screen and (min-width: 768px) {
  .makerContainer {
    aspect-ratio: 768/170;
  }
}

@media only screen and (min-width: 1024px) {
  .makerContainer {
    aspect-ratio: 1024/228;
  }
}

@media only screen and (min-width: 1366px) {
  .makerContainer {
    aspect-ratio: 1366/304;
  }
}

@media only screen and (min-width: 1600px) {
  .makerContainer {
    aspect-ratio: 1600/356;
  }
}

@media only screen and (min-width: 1800px) {
  .makerContainer {
    aspect-ratio: 1800/400;
  }
}

@-webkit-keyframes placeholderShimmer {
  0% {
    background-position: 200% 0;
  }

  100% {
    background-position: -200% 0;
  }
}

@keyframes placeholderShimmer {
  0% {
    background-position: 200% 0;
  }

  100% {
    background-position: -200% 0;
  }
}

.makerContainerWithConfig {
  aspect-ratio: 320 / var(--m);
  animation-name: collapse;
  animation-delay: 4s;
  animation-fill-mode: forwards;
  animation-iteration-count: 1;
  animation-duration: 0.1s;
}

@keyframes collapse {
  from {
    aspect-ratio: initial;
  }

  to {
    aspect-ratio: auto;
  }
}

@media only screen and (min-width: 600px) {
  .makerContainerWithConfig {
    aspect-ratio: 600 / var(--t);
  }
}

@media only screen and (min-width: 1366px) {
  .makerContainerWithConfig {
    aspect-ratio: 1366 / var(--d);
  }
}
