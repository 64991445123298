@import 'Clutch/Styles/breakpoints.module.scss';

.ugcModal {
  display: none;
}

.ugcDrawer {
  display: block;
}

.ugcCard {
  height: 230px;
  width: 230px;
  position: relative;
  overflow: hidden;
}

.videoIcon {
  position: absolute;
  z-index: 2;
  top: 0.5rem;
  right: 0.5rem;
}

.descriptionContainer {
  display: flex;
  justify-content: center;
  text-align: center;
}

.ugcImage {
  object-fit: cover;
  position: absolute;
}

@include mobileMediaQuery {
  .descriptionContainer {
    flex-direction: column;
  }
}

@media (min-width: 1280px) {
  .ugcModal {
    display: block;
  }
  .ugcDrawer {
    display: none;
  }
}
